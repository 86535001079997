<template>
  <div class="container">
    <BasicTitle title="Nueva Citación" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Título *" />
      <BorderInput
        v-model="assembly.data.title"
        label="Título *"
        :disabled="assembly.disabled"
        @keyup.enter="onSave"
      />
      <FormError :show="assembly.rules.title" message="Ingrese un título" />
      <BasicLabel label="Cuerpo del mensaje *" />
      <OutlinedTextArea
        v-model="assembly.data.text"
        :disabled="assembly.disabled"
      />
      <FormError :show="assembly.rules.text" message="Ingrese un texto" />
      <BasicLabel label="Fecha de citación *" />
      <BorderDate
        v-model="assembly.data.appointmentDate"
        label="Fecha de citación *"
        :disabled="assembly.disabled"
      />
      <FormError
        :show="assembly.rules.appointmentDate"
        message="Seleccione una fecha"
      />
      <BasicLabel label="Hora de citación *" />
      <BorderTime
        v-model="assembly.data.appointmentTime"
        label="Hora de citación *"
        :disabled="assembly.disabled"
      />
      <FormError
        :show="assembly.rules.appointmentTime"
        message="Seleccione una hora"
      />
      <div class="link" v-if="!assembly.disabled">
        <label class="link-label" for="indLink"
          >Agregar link de conferencia</label
        >
        <Checkbox id="indLink" value="" v-model="assembly.data.indLink" />
      </div>
      <FormError />
      <div v-show="assembly.data.indLink.length">
        <BasicLabel label="Link *" />
        <OutlinedTextArea
          v-model="assembly.data.link"
          :disabled="assembly.disabled"
        />
        <FormError :show="assembly.rules.link" />
      </div>
      <BorderFile
        v-if="!assembly.disabled"
        :v-model="assembly.selectedDocuments"
        @select="onSelectFiles"
      />
      <FormError v-if="!assembly.disabled" />
    </div>
    <PrimaryButton v-if="!assembly.disabled" label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    BorderDate,
    BorderTime,
    FormError,
    PrimaryButton,
    BorderFile,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const assembly = reactive({
      disabled: false,
      selectedDocuments: [],
      data: {
        id: -1,
        title: "",
        text: "",
        appointmentDate: "",
        appointmentTime: "",
        indLink: "",
        link: "",
      },
      rules: {
        title: false,
        appointmentDate: false,
        appointmentTime: false,
        text: false,
        link: false,
      },
    });

    const onSelectFiles = (ev) => {
      assembly.selectedDocuments = ev.files;
    };

    const validate = () => {
      let valid = true;

      if (!assembly.data.title) {
        assembly.rules.title = true;
        valid = false;
      }
      if (!assembly.data.text) {
        assembly.rules.text = true;
        valid = false;
      }
      if (!assembly.data.appointmentDate) {
        assembly.rules.appointmentDate = true;
        valid = false;
      }
      if (!assembly.data.appointmentTime) {
        assembly.rules.appointmentTime = true;
        valid = false;
      }
      if (assembly.data.indLink && !assembly.data.link) {
        assembly.rules.link = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const responsePath = await store.dispatch(
        actions.documentActions.getPath,
        {
          buildingId: store.state.general.selectedBuilding,
          folderName: assembly.data.title,
          documentTypeName: "Asambleas",
        }
      );

      if (responsePath.ok) {
        let count = 0;
        const documents = [];
        const { length } = assembly.selectedDocuments;
        const finish = async () => {
          const request = {
            id: assembly.data.id,
            title: assembly.data.title,
            text: assembly.data.text,
            appointmentDate: new Date(assembly.data.appointmentDate).getTime(),
            appointmentTime: new Date(assembly.data.appointmentTime).getTime(),
            buildingId: store.state.general.selectedBuilding,
            link: assembly.data.link,
            documents,
          };

          const response = await store.dispatch(
            actions.assemblyActions.create,
            request
          );
          store.commit("setLoading", false);
          if (response.ok) {
            store.commit("setSelectedAssemblies", []);
            router.push("/asambleas/listado");
          } else {
            alert(response.message);
          }
        };

        if (count === length) finish();

        assembly.selectedDocuments.forEach(async (doc) => {
          const name = doc.name;
          const key = `${responsePath.path}${name}`;
          const uploaded = async () => {
            documents.push({
              path: responsePath.path,
              name: name
                .split(".")
                .slice(0, -1)
                .join("."),
              ext: validation.extensionRegex.exec(name)[0],
            });

            count += 1;
            if (count === length) finish();
          };
          aws.uploadFile(key, doc, uploaded);
        });
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: responsePath.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/asambleas/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.DETAIL) {
        const selected = store.state.assembly.selectedAssemblies[0];
        assembly.data.id = selected.id;
        assembly.data.title = selected.title;
        assembly.data.text = selected.text;
        assembly.data.appointmentDate = new Date(selected.appointmentDate);
        assembly.data.appointmentTime = new Date(selected.appointmentTime);
        assembly.data.indLink = selected.link ? "true" : "";
        assembly.data.link = selected.link;
        assembly.disabled = true;
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (assembly.data.title) assembly.rules.title = false;
      if (assembly.data.text) assembly.rules.text = false;
      if (assembly.data.appointmentDate) assembly.rules.appointmentDate = false;
      if (assembly.data.appointmentTime) assembly.rules.appointmentTime = false;
      if (!assembly.data.indLink || assembly.data.link)
        assembly.rules.link = false;
    });

    return { store, assembly, onSave, onSelectFiles };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.link-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
</style>
